/* .hc-nav-link-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.hc-nav-link-container a {
  font-size: 14px;
  border-radius: 5px;
  color: #2194f3;
}

.hc-nav-link-container a:hover {
  background-color: #f0f2f5;
}

.hc-nav-link-container a.active {
  color: white;
  background-color: #2194f3;
} */

.hc-nav {
  border-bottom: 1px solid #2194f3;
  padding: unset;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.hc-nav-link-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.hc-nav-link-container a {
  color: #2194f3;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 10px;
}

.hc-nav-link-container a:hover {
  background-color: #f0f2f5;
}

.hc-nav-link-container a.active {
  color: #fff;
  background: #2194f3;
}

@media (max-width: 420px) {
  .text-cls-nav .fa-fw {
    font-size: 17px !important;
  }
}

.Tab-Search-Container {
  position: relative;
  width: 100%;
}

.Tab-Search-Container input {
  padding: 3px 30px 3px 35px;
  height: 40px;
  color: black;
  font-size: 15px;
  background: white;
  border: 1px solid #ececec;
  border-radius: 9px;
  margin: 0px;
  width: 100% !important;
  outline: none;
}

.Tab-Search-Container i {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  left: 10px;
  color : #908e8e;
}

.Tab-Search-Results {
  background-color: white;
  position: absolute;
  right: 10px;
  top: 60px;
  z-index: 3;
  width: 500px;
  border-radius: 10px;
  border: 1px;
  box-shadow: 0px 4px 8px 0px #888888;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-y: auto;
}

.item-result a {
  border: 1px solid rgb(229, 229, 229);
}

.item-result > div > img {
  height: 25px; 
  width: 25px; 
  margin: 5px; 
  border-radius: 50%; 
  border: 1px solid rgb(229, 229, 229);
}

.nav-item {
  background-color: #ebf5ff;
}

.activity-unread {
  background-color: red;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}