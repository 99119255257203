html {
  height: 100% !important;
}
body {
  height: 100% !important;
}
#root {
  height: 100% !important;
}
.App-Container {
  height: 100% !important;
}

body {
  margin: 0;
  padding: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: inherit !important;
}

.hc-primary {
  background: #2194f3 !important;
}

.hc-primary-text {
  color: #2194f3 !important;
}

.hc-red-text {
  color: #b71e53 !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
  outline: 0;
}

input,
button,
submit {
  border: none;
}

.btn-hubchart {
  background-color: #2194f3 !important;
  background: #2194f3 !important;
  color: white;
  border: 0;
  border-radius: 30px;
  height: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.btn:hover {
  color: #e3e3e3 !important;
}

.btn {
  border-radius: 15px;
}

.btn-hubchart-danger {
  background-color: #d84b55 !important;
  background: #d84b55 !important;
  border-radius: 15px;
}

.btn-hubchart-close {
  background-color: white !important;
  background: white !important;
  color: black;
  border-radius: 15px;
}

.btn-hubchart-success {
  background-color: #7cc15f !important;
  background: #7cc15f !important;
  border-radius: 15px;
}

.btn-hubchart:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.rm-border {
  border-top: none !important;
}

.spin {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.modal-content {
  border-radius: 30px !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-empty {
  font-style: italic;
  color: grey;
  text-align: center;
}

.version-container {
  position: absolute;
  bottom: 0;
  background-color: #e6ebee;
  width: 351px;
  text-align: center;
  padding: 10px;
}

.delete-acct-container {
  text-align: center;
}

.version-text {
  color: #212529;
}

.btn-hubchart-rounded {
  background-color: #2194f3 !important;
  background: #2194f3 !important;
  color: white;
  border: 0;
  border-radius: 0;
  height: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 100%;
  border-radius: 9px;
  height: 30px;
  padding: 0 !important;
}

.urgent-badge {
  position: absolute;
  top: 0px;
  right: 0px;
  color: white;
  background-color: rgb(216, 75, 85);
  padding-left: 4px;
  padding-right: 4px;
  font-size: 9px;
  border-radius: 0 3px 0 0;
}

.attention-badge {
  position: absolute;
  top: 0px;
  right: 0px;
  color: white;
  background-color: rgb(242, 188, 82);
  padding-left: 4px;
  padding-right: 4px;
  font-size: 9px;
  border-radius: 0 3px 0 0;
}

.attention-badge-1 {
  color: white;
  background-color: rgb(242, 188, 82);
  padding-left: 4px;
  padding-right: 4px;
  font-size: 9px;
  border-radius: 0.5rem;
  height: fit-content;
}

.urgent-badge-1 {
  color: white;
  background-color: rgb(216, 75, 85);
  padding-left: 4px;
  padding-right: 4px;
  font-size: 9px;
  border-radius: 0.5rem;
  height: fit-content;
}

.list-group-item {
  border: unset;
  margin-bottom: 8px !important;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: 2px 4px 3px -4px #888888;
  border-radius: 5px 5px 5px 5px;
}

.list-group-item-action {
  width: unset !important;
  cursor: pointer;
}

.list-group-item-action > *:not(:last-child) {
  margin-right: 20px;
}

.list-group-item-action > *:last-child {
  margin-left: auto;
}

.participant-item {
  min-height: 62px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 15px;
}

.participant-item > *:not(:last-child) {
  margin-right: 15px !important;
}

.round {
  position: relative;
  width: 25px;
}

.round-small {
  position: relative;
  width: 20px;
  height: 20px;
}

.round label,
.round-small label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  left: 0;
  position: absolute;
  top: 0;
}

.round label {
  height: 25px;
  width: 25px;
}

.round-small label {
  height: 20px;
  width: 20px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round-small label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"],
.round-small input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label,
.round-small input[type="checkbox"]:checked + label {
  background-color: #2194f3;
  border-color: #2194f3;
}

.round input[type="checkbox"]:checked + label:after,
.round-small input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

/// new checkbox

.round-small-2 {
  position: relative;
  width: 20px;
  height: 20px;
}

.round-small-2 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  left: 0;
  position: absolute;
  top: 0;
}

.round-small-2 label {
  height: 20px;
  width: 20px;
}

.round-small-2 label:after {
  border: 2px solid #fff;
    content: "";
    height: 10px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 4px;
    width: 10px;
    border-radius: 50%;
}

.round-small-2 input[type="checkbox"] {
  visibility: hidden;
}

.round-small-2 input[type="checkbox"]:checked + label {
  background-color: #2194f3;
  border-color: #2194f3;
}

.round-small-2 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-slide-up {
  animation: slide-up 1s ease;
}

.animate-fade {
  animation: fade 1s ease;
}

.nowrap {
  white-space: nowrap;
}

.print-panel {
  /* border: 1px solid gray; */
}

/* Styling for patient info */

.patient-header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.patient-category {
  font-weight: bold;
  margin: 20px 0px;
  color: #686868;
  font-size: 20px;
  border-bottom: 2px solid #8e909e;
}

.padding-10 {
  padding: 10px;
}

.row-nomargin {
  margin: unset !important;
}

.no-padding {
  padding: 0 !important;
}

/* Styles for printing modal */

@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
    // break-inside: avoid;
  }
  #section-to-print {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .modal-print {
    width: 100% !important;
    max-width: 100% !important;
  }
}

/* Force show background graphics when printing */

* {
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/
}

.message-nav {
  margin-left: 20px;
  margin-right: 20px;
}

.ta-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background: #FFFFFF;
  border-radius: 8px;
}

.ta-cont > * {
  margin-right: 10px;
}

.task-alerts-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.task-alerts-container > * {
  margin-right: 5px;
  margin-bottom: 0px;
}

.hc-nav-link {
  font-size: 14px;
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: #2194f3;
  font-weight: bold;
}

.hc-nav-link:hover {
  background-color: #f0f2f5;
  text-decoration: none;
}

.hc-nav-link.active {
  background-color: #2194f3;
  color: white;
  box-shadow: 0px 4px 8px -4px #888888;
}

.hc-nav-link.disabled {
  color: #6c757d;
}

.tab-placeholder {
  width: 100%;
  position: absolute;
  top: 40%;
}

.priority-text {
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 300;
}

.inbox-menu-button {
  padding: 1px 7px;
  margin: 7px;
  font-size: 20px;
  cursor: pointer;
  color: #908e8e;
  background-color: #f5f5f5;
  border-radius: 50%;
}

.dropdown-item:focus,
.dropdown-item:hover {
  // color: #fff;
  text-decoration: none;
  // background-color: #2094f3;
}

/* LOADER */

.loader-logo {
  /* margin-right:10px; */
}

.loader-bar-container {
  margin-top: 10px;
}

.loader-message {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}

.loader {
  position: fixed;
  display: inline-grid;
  top: 0;
  left: 0;
  text-align: center;
  color: white;
  align-content: center;
  height: 100%;
  width: 100%;
  background-color: #000000c2;
  z-index: 1002;
}

.loader-container {
  // width: 500px;
}

.loader-brand {
  /* margin: 5px; */
  color: #1e94f3;
  font-weight: 500;
}

/* Input group styles */

.hc-input-addon {
  background-color: #fff;
  color: #2194f3;
  border: 0;
}

// input.form-control {
//     border-radius: 0;
// }

.input-group {
  position: relative;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.input-group:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.input-group input {
  outline: none;
  -webkit-appearance: none;
  border: 0;
  height: 40px;
}

.input-group select {
  outline: none;
  border: 0;
}

/* Conference */

.list-card {
  min-height: 48px;
  border: 1px solid #ececec !important;
  box-shadow: 0px 4px 7px -4px #888888;
  border-radius: 5px 5px 5px 5px;
  margin-bottom: 10px;
  position: relative;
  display: grid;
  grid-template-columns: 42px minmax(0, 1fr) auto;
  grid-column-gap: 10px;
  padding: 10px;
}

.list-card .profile-image {
  width: 42px;
  height: 42px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  border-radius: 15px !important;
}

.list-card .name {
  color: rgb(0, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  width: 100%;
  margin: 0px;
}

.list-card .text-content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(153, 153, 153, 1);
  font-size: 13px;
  font-weight: 300;
  margin: 0;
}

.search-input-container input {
  padding: 3px 30px 3px 35px;
  height: 30px;
  color: black;
  font-size: 15px;
  background: #ebecef;
  border: 0px;
  border-radius: 9px;
  margin: 0px;
  width: 100% !important;
  outline: none;
}

.search-input-container {
  position: relative;
}

.search-input-container i {
  position: absolute;
  top: 8px;
  left: 12px;
  color: #908e8e;
}

/* For conference call container */

.flex-container {
  display: flex;
  border: 1px solid green;
  justify-content: space-between;
  padding: 5px;
  margin-top: 10px;
}

.flex-item {
  margin: 5px;
}

.flex-item:first-child {
  background-color: aqua;
  flex: 2;
}

.flex-item:nth-child(2) {
  background-color: blueviolet;
  flex: 1;
}

.flex-item:last-child {
  background-color: beige;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
}

#remote-media video {
  width: inherit;
  height: 200px;
}

/* For contacts */

.contact-menu-overlay {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

/* .contact-menu-button {
    padding: 0px 4px;
    margin: 4px;
    font-size: 17px;
    cursor: pointer;
    color: #2094f3;
    background-color: #f5f5f5;
    border-radius: 50%;
    box-shadow: 0px 4px 7px -4px #888888;
} */

.contact-menu-button {
  padding: 1px 10px;
  margin: 7px;
  font-size: 15px;
  cursor: pointer;
  color: #2094f3;
  background-color: #f1f1f1;
}

.contact-menu-button-active {
  padding: 1px 10px;
  margin: 7px;
  font-size: 15px;
  cursor: pointer;
  background-color: #2094f3;
  color: #f1f1f1;
}

.contact-menu-button-active:hover {
  color: #ffffff;
}

.center-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Conference */

.list-card-conference {
  min-height: 48px;
  border: 1px solid #ececec !important;
  box-shadow: 0px 4px 7px -4px #888888;
  border-radius: 5px 5px 5px 5px;
  margin-bottom: 10px;
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  column-gap: 10px;
  padding: 10px;
  background-color: #fff;
}

.list-card-conference .name {
  color: rgb(0, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  width: 100%;
  margin: 0px;
}

.list-card-conference .text-content {
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
  color: rgba(153, 153, 153, 1);
  font-size: 13px;
  font-weight: 300;
  margin: 0;
}

/* Override bootstraps badge-primary */

.badge-primary {
  color: #fff;
  background-color: #2094f3;
}

.img-conference-wrapper {
  border-radius: 50%;
  overflow: hidden;
}

.conference-participants {
  display: flex;
  flex-direction: row;
}

.datepicker-schedule {
  padding: 3px 10px 3px 10px;
  height: 30px;
  color: black;
  font-size: 15px;
  background: #ebecef;
  border: 0px;
  border-radius: 9px;
  margin: 0px;
  width: 100% !important;
  outline: none;
}

.btn-join-call {
  border-radius: 20px;
  font-weight: bold;
}

.btn-join-call:disabled {
  background-color: #dfe4e7;
  border-color: #dfe4e7;
  color: grey;
}

.bubble-list-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.bubble-list-item.reverse {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.bubble-from {
  max-width: 380px;
  word-wrap: break-word;
  line-height: 16px;
  white-space: pre-line;
  min-height: 32px;
}

.bubble-from.them {
  position: relative;
  padding: 7px 18px;
  background-color: #f2f4ff;
  border-radius: 15px;
  color: #000000db;
  float: left;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.bubble-from.me {
  position: relative;
  padding: 7px 18px;
  color: #000000db;
  background: #cae9ff;
  border-radius: 15px;
  float: right;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.bubble-avatar-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.bubble-avatar {
  border-radius: 50%;
  overflow: hidden;
}

.bubble-text-main {
  font-weight: bold;
  margin-bottom: 6px;
}

.bubble-text-sub {
  margin-bottom: 10px;
}

.bubble-text-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.fa-rotate-45-left {
  filter: none;
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bubble-button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #2194f3;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 6px;
}

.bubble-text-ended {
  font-weight: bold;
  font-style: italic;
  color: #908d8d;
  font-size: 15px;
  margin-bottom: 6px;
}

.text-missed {
  font-weight: bold;
  color: red;
  margin: 0px;
  font-size: 14px;
}

.text-completed-call {
  font-weight: bold;
  color: #2194f3;
  margin: 0px;
  font-size: 14px;
}

.fa-phone-end {
  filter: none;
  -o-transform: rotate(-134deg);
  -webkit-transform: rotate(-134deg);
  transform: rotate(-134deg);
}

.value-group {
  position: relative;
  width: 100%;
  border-radius: 0px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.text-blank {
  font-style: italic;
  color: grey;
}

/* For inbox */

.message-menu-overlay {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.badge-patient {
  background-color: #ff99c7;
  color: white;
}

.attentioned-users {
  color: #2193f3;
  font-weight: bold;
  margin-bottom: 3px;
}

.urgent-users {
  color: #2193f3;
  font-weight: bold;
  margin-bottom: 3px;
}

.message-text-content {
  line-height: 1.3;
}

/* For PDF viewer */

.PDF-Document {
  height: calc(100vh - 150px);
  max-width: 100%;
}

.PDF-Page {
  height: 100%;
  max-width: 100%;
}

/* For inbox message hover preview */

.Message-Tooltip {
  /* color: white !important;
    background-color: #2194f3 !important; */
  color: white !important;
  background-color: #2194f3 !important;
  opacity: 1 !important;
  z-index: 1;
}

.Message-Tooltip.place-right::after {
  border-right: 6px solid #2194f3 !important;
}

.quick-reply-input {
  padding: 3px 10px 3px 10px;
  height: 30px;
  color: black;
  font-size: 15px;
  background: #fff;
  border: 0px;
  border-radius: 9px;
  margin: 0px;
  outline: none;
}

.Patient-Thread-Name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin: 0;
  font-weight: 500;
}

.modal-full-height {
  height: calc(100vh - 60px) !important;
}

.modal-small-height {
  height: calc(40vh - 60px) !important;
}

.mycard-1 {
  padding: 10px 0px !important;
}

.mycard-1 > * {
  user-select: none;
}

.pin-container {
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 5px;
  overflow: hidden;
}

.pin-background {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid #2194f3;
}

.pin-icon {
  position: absolute;
  top: 4px;
  left: 4px;
}

.link-nodecor {
  text-decoration: none;
}

.layout-recent-container {
  display: flex;
  flex-direction: row;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

label {
  cursor: pointer;
}

.hc-card {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.hc-card textarea {
  outline: none;
  -webkit-appearance: none;
  border: 0;
  resize: none;
  width: 100%;
}

.hc-card input {
  outline: none;
  -webkit-appearance: none;
  border: 0;
  width: 100%;
  height: 40px;
}

.shift-down-4 {
  transform: translateY(4px);
}

.hover-primary:hover {
  color: #2194f3 !important;
}

a.no-underline {
  text-decoration: none !important;
}

.text-grey {
  color: grey;
}

.hc-bg-primary {
  background: #2194f3;
  border-color: #2194f3;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #444444 none;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #f7f8f9;
}

*::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
  border-radius: 30px;
}

a {
  color: #2194f3;
}

.modified-collection {
  position: absolute;
  width: 100%;
}

.hc-nav-pills {
  background: #fff;
  border-radius: 5px;
  padding: 3px;
}

.hc-nobreak {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hoverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.25s ease;
  background-color: #0000009c;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.hoverlay:hover {
  opacity: 1;
}

.c-patient {
  border-color: #e5e5e5;
  height: 40px;
}

.c-patient:hover {
  box-shadow: 0px 0px 4px 0px #e5e5e5;
}

.view-task-btn {
  border: 1px solid #c4e7fe;
  height: 32px;
  margin-left: auto;
  font-size: 12px;
  background: #c4e7fe;
  color: #000000;
  width: 125px;
}
